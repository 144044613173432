<template>
    <!-- form -->
    <validation-observer ref="simpleRules">
        <b-form> 
            <b-row>
                <b-col>
                    <h4 class="text-primary" v-show="input.id ==null">
                        <feather-icon size="20" icon="PlusCircleIcon" /> Tambah kategori</h4>
                        <h4 class="text-primary" v-show="input.id !=null">
                        <feather-icon size="20" icon="PlusCircleIcon" /> Edit kategori</h4>
                </b-col>
            </b-row>
            <hr style="margin-top: -1px;">
            <b-row>
                <b-col md="12">
                    <b-form-group label="Kategori Pengaduan">
                        <validation-provider #default="{ errors }" name="kategori Pengaduan" rules="required">
                            <b-form-input v-model="input.jenis_aduan" :state="errors.length > 0 ? false:null"
                                placeholder="Kategori Pengaduan" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label="keterangan">
                        <b-form-textarea id="textarea-default" v-model="input.keterangan" placeholder="Keterangan"
                            rows="3" />
                    </b-form-group>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col cols="12">
                    <b-button variant="primary" type="submit" @click.prevent="validationForm" size="sm" v-show="input.id ==null">
                        <feather-icon size="15" icon="PlusCircleIcon" class="mr-1" />
                        Simpan
                    </b-button>
                    <b-button variant="info" type="submit" @click.prevent = "UpdateData()" size="sm" v-show="input.id !=null">
                        <feather-icon size="15" icon="SaveIcon" class="mr-1" />
                        Update
                    </b-button>
                    <b-button variant="warning" @click="close()" size="sm" class="ml-1">
                        <feather-icon size="15" icon="RefreshCcwIcon" class="mr-1" />
                        Batal
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>
</template>

<script>
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import {
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText,
        BFormTextarea
    } from 'bootstrap-vue'
    import {
        required,
    } from '@validations'

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            BCardText,
            BFormInput,
            BFormGroup,
            BForm,
            BRow,
            BCol,
            BButton,
            BFormTextarea
        },
        data() {
            return {
                required,
            }
        },
        props: {
            input: {

            }
        },
        computed: {
            inputData: {
                get: function () {
                    return this.input;
                },
                set: function (newValue) {
                    return this.input = newValue;
                },
            },

        },
        emits: ["simpan", "UpdateData"],
        methods: {

            close() {
                this.input = {} 
            },
            validationForm() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.$emit('simpan', this.input);
                        this.input = {} 
                        this.close();
                    }
                })
            },
            UpdateData(){
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.$emit('UpdateData', this.input);
                        this.input = {} 
                        this.close();
                    }
                })
            },
            refresh() {

            }
        },
    }
</script>