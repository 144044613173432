<template>
    <b-row>
        <b-col md="3" sm="4" class="my-1">
            <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"> </label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
        </b-col>
        <b-col md="3" sm="8" class="my-1">

        </b-col>
        <b-col md="6" class="my-1">
            <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">
                            Clear
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </b-col>

        <b-col cols="12">
            <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(kategori_pengaduan)="data">
                    <b-row>
                        <b-col md="9"> 
                            <b-row>
                                <b-col>
                                    <small>Kategori Aduan</small>
                            <h6>{{ data.item.jenis_aduan }}</h6>
                            <small>keterangan</small>
                            <h6><small>{{ data.item.keterangan }}</small></h6> 
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="6">
                                    <b-badge variant="primary" class="b-blok" size="sm"
                                        v-show="data.item.status == 'Aktif'" @click="UpdateStatus(data.item)">
                                        <feather-icon size="15" icon="CheckIcon" align="right" /> Status Aktif</b-badge>
                                    <b-badge variant="danger" class="b-blok"  size="sm" v-show="data.item.status != 'Aktif'"
                                        @click="UpdateStatus(data.item)">
                                        <feather-icon size="15" icon="CheckIcon" align="right" /> Status Non Aktif</b-badge>
                                </b-col>
                            </b-row> 
                        </b-col>
                        <b-col md="3">
                           
                            <b-row>
                                <b-col md="12">
                                    <b-badge variant="warning" class="ml-1 b-blok" size="sm" @click="EditData(data.item)">
                                        <feather-icon size="15" icon="EditIcon" /> Edit</b-badge>
                                </b-col>
                                <b-col md="12">
                                    <b-badge variant="danger" class="ml-1 b-blok" size="sm" @click="DeleteData(data.item)">
                                        <feather-icon size="15" icon="TrashIcon" /> Delete</b-badge>
                                </b-col>
                            </b-row>




                        </b-col>
                    </b-row>
                </template>
            </b-table>
        </b-col>

        <b-col cols="6">
            Total Data {{ totalRows }}
        </b-col>
        <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
        },
        props: {
            items: {

            }
        },
        emits: ["UpdateStatus", "EditData", "DeleteData"],
        data() {
            return {
                perPage: 5,
                pageOptions: [3, 5, 10, 50, 100],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                fields: [{
                        key: 'kategori_pengaduan',
                        label: '',
                    },

                ],


            }
        },
        
        computed: {
            
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        methods: {
            UpdateStatus(value) {
                this.$emit("UpdateStatus", value);
            },
            EditData(value){
                this.$emit("EditData", value);
            },
            DeleteData(value){
                this.$emit("DeleteData", value);
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>
<style>
    .b-blok {
        display: block;
        width: 100%;
        margin-bottom: 5PX;
        ;
    }
</style>