<template>
  <div>
    <b-row>
      <b-col md="7">
        <b-card>
          <tabel :items=items @UpdateStatus=UpdateStatus @EditData=EditData @DeleteData=DeleteData></tabel>
        </b-card>
      </b-col>
      <b-col md="5">
        <b-card>
          <form-add :input=input @simpan=simpan @UpdateData=UpdateData></form-add>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import Base from '@/config/Mixins_base';
  import axios from '@/config/Axios';

  import tabel from './component/tabel.vue';
  import formAdd from './component/form_add.vue'
  import {
    BRow,
    BCol,
    BCard
  } from 'bootstrap-vue'

  export default {
    mixins: [Base],
    components: {
      BRow,
      BCol,
      tabel,
      formAdd,
      BCard

    },
    data() {
      return {
        items: [],
        data_edit: {},
        input: {}
      }
    },
    mounted() {
      this.cek_token();
      this.loadData()
    },
    methods: {
      EditData(value) {
        this.input = value;
      },
      async UpdateData(value) {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/pengaduan/setup/uppdate',
            data: value,
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('primary', "Update Data", response.data.message);
            self.loadData();
            self.input = {}

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async simpan(value) {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/pengaduan/setup/insert',
            data: value,
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('warning', "Info", response.data.message);
            self.loadData();
            self.input = {};

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async loadData() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/pengaduan/setup/load',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.items = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },

      async UpdateStatus(value) {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/pengaduan/setup/non_aktif',
            data: {
              id: value.id
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('info', "Info Transaksi", response.data.message);
            self.loadData();
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async DeleteData(value) {
        if (confirm("Apakah anda yakin akan menghapus data ini") == true) {
          const self = this;
          await axios({
              method: 'POST',
              url: '/api/pengaduan/setup/delete',
              data: {
                id: value.id
              },
              headers: {
                'Authorization': self.isAuthenticated
              }
            })
            .then(function (response) {
              self.notification('info', "Info Transaksi", response.data.message);
              self.loadData();
            }).catch(err => {
              self.pesan = err.message;
              self.notification('warning', "Error", err.message);
            });
        }
      },
    }

  }
</script>

<style>

</style>